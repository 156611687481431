



































































import { Component, Vue } from "vue-property-decorator";
import registryService from "@/services/registry.service";
import {
  BLink,
  BFormRow,
  BCol,
  BInputGroup,
  BFormSelect,
  BFormInput,
  BButton,
  BButtonClose,
  BModal
} from "bootstrap-vue";

@Component({
  components: {
    BLink,
    BFormRow,
    BCol,
    BInputGroup,
    BFormSelect,
    BFormInput,
    BButton,
    BButtonClose,
    BModal
  }
})
export default class ChangeSerialCounter extends Vue {
  private hidden = true;
  private serialCounters: Record<string, number> = {};
  private newSerialCounter = {
    model: "",
    counter: -1
  };
  private showConfirmModal = false;

  private async loadSerialCounters() {
    this.serialCounters = await registryService.getSerialCounters();
    console.log("serialcounters:", this.serialCounters);
  }

  private async updateSerialCounter() {
    if (this.newSerialCounter.model && this.newSerialCounter.counter >= 0) {
      const newSerialCounter = await registryService.setSerialCounter(
        this.newSerialCounter.model,
        this.newSerialCounter.counter,
        this.serialCounters[this.newSerialCounter.model]
      );
      this.serialCounters[newSerialCounter.model] = newSerialCounter.counter;
      this.newSerialCounter = { model: "", counter: -1 };
      this.hidden = true;
    }
  }

  created() {
    this.loadSerialCounters();
  }
}
