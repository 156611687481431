












































import { Component, Vue } from "vue-property-decorator";
import registryService from "@/services/registry.service";
import {
  BAlert,
  BFormRow,
  BCol,
  BFormInput,
  BButton,
  BButtonClose,
  BModal
} from "bootstrap-vue";

@Component({
  components: {
    BAlert,
    BFormRow,
    BCol,
    BFormInput,
    BButton,
    BButtonClose,
    BModal
  }
})
export default class ImportFromReddit extends Vue {
  private redditPost: string | null = null;
  private errorMessage: string | null = null;
  private showConfirmModal = false;

  private async importRedditRequest() {
    try {
      const newRequest = await registryService.importRedditRequest(
        this.redditPost ?? ""
      );
      this.$emit("success", newRequest);
    } catch (error) {
      this.errorMessage =
        "Error: " +
        (error.response.data.errorMessage || error.response.data.message);
      this.$emit("error", this.errorMessage);
    }
  }

  private get validated() {
    const findIdInUrl = (this.redditPost ?? "").match(
      /^(?:(?:https:\/\/)?(?:www\.)?(?:reddit\.com\/r\/(\w+)\/comments\/|redd\.it\/)?)?(?<postId>\w{6,7})(?:\/?[\w]+\/?)?(?:\?.*)?$/
    );
    return findIdInUrl?.groups?.postId;
  }
}
