






































































import { Component, Vue } from "vue-property-decorator";
import { Serial } from "@/types";
import registryService from "@/services/registry.service";
import ChangeSerialCounter from "@/components/ChangeSerialCounter.vue";
import ImportFromReddit from "@/components/ImportFromReddit.vue";
import SerialRequestCard from "@/components/SerialRequestCard.vue";
import { BSpinner, BButton, BAlert } from "bootstrap-vue";

@Component({
  head: {
    title: function() {
      return { inner: this.$options.name };
    }
  },
  components: {
    SerialRequestCard,
    ChangeSerialCounter,
    ImportFromReddit,
    BSpinner,
    BButton,
    BAlert
  }
})
export default class Manage extends Vue {
  private serialRequests: Serial[] = [];
  private noOpenRequests = false;
  private orderBy: "created" | "updated" = "created";
  private busy = false;
  private justIssued: string[] = [];
  private errorMessage: string | null = null;
  private requestsInEdit = 0;

  private async loadSerialRequests() {
    const serialRequests = await registryService.getOpenRequests();
    console.log("requets: ", serialRequests);
    if (!serialRequests.length) this.noOpenRequests = true;
    this.serialRequests = serialRequests.map(request => {
      //TODO: received value should already be a number
      return { ...request, updated: Number(request.updated) };
    });
  }

  private userHasMultipleRequests(userId: string): boolean {
    return this.serialRequests.filter(sr => sr.userId === userId).length > 1;
  }

  private warnings(request: Serial): string | null {
    const warningConcat = [
      this.userHasMultipleRequests(request.userId)
        ? "This user has multiple pending requests."
        : null
    ].filter(x => x);
    return warningConcat.length ? warningConcat.join(" ") : null;
  }

  private async addImported(serialRequest: Serial) {
    this.serialRequests.push(serialRequest);
    this.noOpenRequests = !this.serialRequests.length;
  }

  private async issueSelected() {
    this.busy = true;
    for (const selectedRequest of this.serialRequests
      .filter(request => request.status === "issued")
      .sort((a, b) => a.updated - b.updated)) {
      try {
        const result = await registryService.updateRequest(
          selectedRequest.userId,
          selectedRequest.created.toString(),
          selectedRequest
        );
        if (result && result.serial) {
          console.log(result);
          this.justIssued = [...this.justIssued, result.serial];
          this.serialRequests = this.serialRequests.filter(
            request => request !== selectedRequest
          );
        }
      } catch (e) {
        this.busy = false;
        this.errorMessage = selectedRequest._error =
          "Error: " + (e.response.data.errorMessage || e.response.data.message);
        break;
      }
    }
    this.busy = false;
  }

  private requestDeleted(serialRequest: Serial) {
    this.serialRequests = this.serialRequests.filter(
      request => request !== serialRequest
    );
  }

  private requestError(errorMessage: string) {
    this.errorMessage = errorMessage;
  }

  private changeInEdit(inEdit: number) {
    this.requestsInEdit = inEdit;
  }

  private get orderedSerialRequests() {
    const serialRequests = this.serialRequests;
    if (serialRequests.length > 0) {
      return serialRequests.sort((a, b) => {
        return a[this.orderBy] - b[this.orderBy];
      });
    } else return undefined;
  }

  private get selectedSerials() {
    return this.serialRequests.filter(request => request.status === "issued");
  }

  created() {
    this.loadSerialRequests();
  }
}
